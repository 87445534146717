<template>
	<div class="container-fluid">
		<section class="col-12 pl-2 analytics">
			
			<div class="event_processing" v-show="isLoading" v-if="!$store.state.active || ($store.state.active && 'analytics' in $store.state.permissions)">
				<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
				<img src="/img/ajax-loader.gif" />
			</div>
			
			<div class="topfilter pt-4" v-if="!$store.state.active || ($store.state.active && 'analytics' in $store.state.permissions)">
				<div class="row">
					<div class="col-6">
						<div class="title">{{ $t('analytics') }}</div>
					</div>
					<div class="col-6">
						<!--VueCtkDateTimePicker v-model="dateRange" @validate="getReport()" right range label="Date & Time" /-->
						<div class="form-inline" style="justify-content: flex-end;">
							<div class="form-group mr-3" style="width: 210px;">
						
								<date-range-picker ref="picker" :showWeekNumbers="true" :showDropdowns="true" v-model="dateRange" @update="updateStats();" :locale-data="this.is_fr? this.localeRangeFr :this.localeRangeEn ">
									<template v-slot:input="picker" style="min-width: 350px;">
										<div :title="dateRange.startDate">
											<i class="fa fa-calendar pr-1 d-block"></i>
											<span class="d-block" v-if="dateRange.startDate">{{ dateRange.startDate | date }} - {{ dateRange.endDate | date }}</span>
											<span class="d-block" v-else>{{ $t('AllTime') }}</span>
											
											<i class="fa fa-close init-dateRange" @click="InitDateRange" v-if="Object.keys(dateRange).length > 0"></i>
										</div>
									</template>
								</date-range-picker>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div role="tablist" v-if="!isLoading && (!$store.state.active || ($store.state.active && 'analytics' in $store.state.permissions))">
				
				
				<div class="custom-tabs setting-tabs">
					<b-tabs content-class="mt-3">
						<b-tab :title="$t('Overview')" active>
							<div class="w-90">
								<div class="row">
									<div class="col-12">
										<h6 class="oml-h6Title">{{ $t('Overview') }} <i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i></h6>
									</div>
									<div class="col-12 mx-auto mt-4">
										<div v-if="dataAvailable">
											<div class="analytics-overview">
												<div class="row">
													<div class="col-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 2rem;">
													<div class="card card-2" v-if="typeof( funnel_chart_data[0] ) != 'undefined'">
														<div style="padding: 2rem;">
															<h3 class="graph-title">
																<div>{{ $t('YourLeadsFunnel') }}</div>
																<small style="font-size: 12px;">{{ $t('AnalyticsFunnel') }}</small>
															</h3>
															
															<funnel-chart v-if="funnel_chart_data[0].values > 0" :data="funnel_chart_data"></funnel-chart>
														</div>
													</div>
													</div>
													<div class="col-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 2rem;">
														<div class="charts_29">
															<div class="card card-2" style="padding: 2rem;">
																<h3 class="graph-title">{{ $t('Leadsbystatus') }}</h3>
																<status-chart v-if="status_chart_data.length > 0" :data="status_chart_data"></status-chart>
															</div>
														</div>
													</div>
													<div class="col-12">
														<div class="charts_24" style="padding-top: 0;">
															<div class="card card-2" style="padding: 2rem;">
																<h3 class="graph-title">{{ $t('Leadsbysources') }}</h3>
																<source-chart v-if="source_chart_data.length > 0" :data="source_chart_data"></source-chart>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="no-graphs-data text-center" v-else>
											<i class="fas fa-chart-line"></i>
											<strong>{{ $t('Nodatayet') }}</strong>
											<p>{{ $t('Unfortunately') }}</p>
											<p>
												{{ $t('TitleNoteLink') }}
												<router-link :to="{name: 'newSource'}">
													{{ $t('AddAnotherSource') }}
												</router-link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab :title="$t('TopSources')">
							<div class="w-90">
								<div class="row">
									<div class="col-12">
										<h6 class="oml-h6Title">{{ $t('TopSources') }} <i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i></h6>
									</div>
									<div class="col-12 mx-auto">
										<data-table-top-sources :topSources="topSources" :columns="topSourcesColumns" :selectedAction="selectedAction"></data-table-top-sources>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab :title="$t('topFbC')">
							<div class="w-90">
								<div class="row">
									<div class="col-12">
										<h6 class="oml-h6Title">{{ $t('topFbC') }} <i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i></h6>
									</div>
									<div class="col-12 mx-auto">
										<data-table-top-fb-campaigns :topFbCampaigns="topFbCampaigns" :columns="topFbCampaignsColumns" :selectedAction="selectedAction"></data-table-top-fb-campaigns>
									</div>
								</div>
							</div>
						</b-tab>
					</b-tabs>
				</div>
				
			</div>
			<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('analytics')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
			</div>
		</section>
	</div>
</template>

<script>
    
	import FunnelChart from "@/components/charts/FunnelChart.vue";
    import StatusChart from "@/components/charts/StatusChart.vue";
    import SourceChart from "@/components/charts/SourceChart.vue";
	import dataTableTopSources from "@/plugins/md/dataTable/datatable-topsources.vue";
	import dataTableTopFbCampaigns from "@/plugins/md/dataTable/datatable-topfbcampaigns.vue";
	
	import DateRangePicker from 'vue2-daterange-picker'
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
	
    export default {
        name: "dashboard-module",
        data() {
            return {
                type	: '',
                date	: {},
                source	: null,
				is_fr : false,
				localeRangeEn : { applyLabel: 'Apply',
					cancelLabel: 'Cancel',
					weekLabel: 'W',
					customRangeLabel: 'Custom Range',
					daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
					monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], },
				localeRangeFr : { applyLabel: 'Appliquer',
					cancelLabel: 'Annuler',
					weekLabel: 'S',
					customRangeLabel: 'Période personnalisée',
					daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
					monthNames: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'], },
				isLoading: false,
				dataAvailable: true,
				dateRange: {},
				
				funnel_chart_data: [/* 
					{ values:[1],	backgroundColor: '#48a6e8' },
					{ values:[1], 	backgroundColor: '#0d4770' },
					{ values:[1], 	backgroundColor: '#57a869' },
				 */],
				status_chart_data: [],
				source_chart_data: [],
				topSources: [],
				topFbCampaigns: [],
				topSourcesColumns: [
					{ title: this.$t('Source'),              column: 'source',               enable: true },
					{ title: this.$t('Leads'),               column: 'leads',                enable: true },
					{ title: this.$t('ProcessingRate'),     column: 'processingRate',       enable: true },
					{ title: this.$t('QualifiedLeads'),     column: 'qualifiedLeads',       enable: true },
					{ title: this.$t('QualificationRate'),  column: 'qualificationRate',    enable: true }
				],
				topFbCampaignsColumns: [
					{ title: this.$t('Campaign'),            column: 'campaign',             enable: true },
					{ title: this.$t('Adset'),               column: 'adset',                enable: true },
					{ title: this.$t('Ads'),                 column: 'ads',                  enable: true },
					{ title: this.$t('Leads'),               column: 'leads',                enable: true },
					{ title: this.$t('ProcessedLeads'),     column: 'processed_leads',      enable: false },
					{ title: this.$t('ProcessingRate'),     column: 'processing_rate',      enable: true },
					{ title: this.$t('QualifiedLeads'),     column: 'qualified_leads',      enable: true },
					{ title: this.$t('QualificationRate'),  column: 'qualification_rate',   enable: true }
				],
				selectedAction : []
            }
        },
		methods: {
			
			InitDateRange () {
				this.dateRange = {}
				this.updateStats()
				
			},			
            getReport() {
				
				let that = this
				that.isLoading = true
				let url   = '/analytics'
				let method= 'POST'
				let data  = {dateRange:that.dateRange}
				
				// Remove timezone to get the UTC date
				if('startDate' in data.dateRange) {
					data.dateRange.startDate = new Date(data.dateRange.startDate.getTime() - data.dateRange.startDate.getTimezoneOffset() * 60  * 1000)
					data.dateRange.endDate = new Date(data.dateRange.endDate.getTime() - data.dateRange.endDate.getTimezoneOffset() * 60 * 1000)
				}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let statsData = response.data
					that.funnel_chart_data = statsData.funnel_chart_data
					that.status_chart_data = statsData.status_chart_data
					that.source_chart_data = statsData.source_chart_data
					that.isLoading = false
				}).catch(( {response} ) => {
					console.log(response)
					that.isLoading = false
				})
				
            },
			getSources(action = '') {
				let that = this
				that.loadingData = true
				if(action == 'filter') {
					that.current_page = 1
				}
				
				that.leadGetter = false
				
				let url   = 'sources?page=' + that.current_page
				let method= 'POST'
				let data  = {range:that.dateRange, keyword:that.searchQuery}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if(action == 'filter') {
						that.topSources = []
					}
					let topSources = response.data.mySources.data;
                    that.topSources = []
					topSources.forEach( function(sourceData) {
						that.topSources.push(
							{
								id                  : sourceData.id,
								source              : sourceData.name,
								type                : sourceData.type,
								created_at          : sourceData.created_at,
								leads               : sourceData.leads,
								processingRate      : sourceData.processed_rate,
								qualifiedLeads      : sourceData.qualified_entries_count,
								qualificationRate   : sourceData.qualified_rate,
								actions             : 'actions',
								sourceData		    : sourceData.data,
								confirmation        : sourceData.confirmation,
								notification        : sourceData.notification
							}
						)
					} );
					that.current_page += 1;
					that.last_page = response.data.topSources.last_page;
					that.leadGetter = true;
					
					that.loadingData = false
					
				}).catch(( {response} ) => {
					console.log(response)
					that.loadingData = false
				})
				
			},
			getTopFbCampaigns() {
				let that = this
				let url = '/top_fb_campaigns'
				let method = 'POST'
				let data = {dateRange:that.dateRange}

				// Remove timezone to get the UTC date
				if('startDate' in data.dateRange) {
					data.dateRange.startDate = new Date(data.dateRange.startDate.getTime() - data.dateRange.startDate.getTimezoneOffset() * 60  * 1000)
					data.dateRange.endDate = new Date(data.dateRange.endDate.getTime() - data.dateRange.endDate.getTimezoneOffset() * 60 * 1000)
				}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.topFbCampaigns = response.data.facebook_adsets;
				}).catch(( {response} ) => {
					console.log(response)
				})
            },
            updateStats() {
                this.getReport()
                this.getSources()
                this.getTopFbCampaigns()
            }
        },
        components: {
            SourceChart,
            StatusChart,
            FunnelChart,
			DateRangePicker,
            dataTableTopSources,
            dataTableTopFbCampaigns
        },
        mounted() {
			//console.log(localStorage.getItem('i18n_lang'));
			this.getReport()
			this.getSources()
			this.getTopFbCampaigns()
            this.$root.$on('bv::collapse::state', () => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            })
			if(localStorage.getItem('i18n_lang') == 'fr'){
				this.is_fr = true
			}else{
				this.is_fr = false
			}
				
        },
        computed: {
			
        },
		watch: {
			dateRange: function (val) {
				if(val == null) {
					this.updateStats()
				}
			},
		},
		filters: {
			date: function(myDate) {
				if (!myDate) { return '(n/a)'; }
				let str = new Date(myDate);
				return ((str.getMonth() < 9) ? '0' : '') + (str.getMonth() + 1) + '/' +  ((str.getDate() < 10) ? '0' : '') + str.getDate() + '/' + str.getFullYear();
			}
		}
    }
</script>

<style lang="scss">
    .fa-angle-double-up {
        -webkit-text-stroke: 1px #2baee2;
    }
    #filter_by_type {
        /* color: #6f6f6f; */
        width: 100%;
        padding-top: 5px;
        height: 40px;
        font-size: 15px;
        option {
            color: #737376;
            font-size: 15px;
        }
		color: #b5b5b5;
        font-size: 14px;
    }
    .no-graphs-data {
        margin-top: 50px;
    }
    .input-group-addon {
        color: #b4b3b3;
        background-color: transparent;
    }
    .form-control:focus {
        border-color: #d8dde5;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }	
	.analytics {
		.daterangepicker.openscenter {
			transform: translate(-85%) !important;
		}
        .btn-info, .btn-info:focus{
            color: #000;
            background-color: #fff;
            border-color: #fff;
            font-weight: bold;
            text-align: left;
            padding: 0.375rem;
        }
        .btn-info:hover, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
            color: #000;
            background-color: #f8fdff;
            border-color: #f8fdff;
            font-weight: bold;
        }
        .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info[href]:hover, .btn-info[href]:focus, .btn-info[href]:active, .btn-info[type]:hover, .btn-info[type]:focus, .btn-info[type]:active {
            box-shadow: none;
        }
        .btn-info .fa {
            color: #2baee2;
            border-radius: 60px;
            padding: 0.5em 0.6em;
            background-color: #ecfbff;
            font-size: 16px;
            margin-right: 7px;
        }	
        .btn-info .fa.float-right {
            margin-right: 0px;
        }	
        .card {
            background: #fff;
            border: 0px;
            display: inline-block;
            position: relative;
            width: 100%;
        }
        .card-header {
            border-radius: 5px;
            border: 1px solid #ececec;
            border-left: 8px solid #21bcf4;
        }
	}
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
    .analytics-overview {
        .card {
            background: #fff;
            border-radius: 2px;
            display: inline-block;
            position: relative;
            width: 100%;
            border: 1px solid rgba(0,0,0,.125);
        }
        
        .card-1 {
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        }
        
        .card-1:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
        
        .card-2 {
            box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.1);
        }
        
        .card-3 {
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        }
        
        .card-4 {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
        
        .card-5 {
            box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        }
        .graph-title {
            font-size: 1.2rem;
            margin-bottom: 3rem;
            color: #2c3348;
            margin-top: 0;
        }
    }	
	.svg-funnel-js.svg-funnel-js--vertical {
		padding-left: 30px !important;
		padding-right: 0px !important;
	}
</style>
